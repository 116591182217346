import React from "react"
import Layout from "../components/layout"
import NavStatic from "../containers/navStatic/NavStatic"
import Footer from "../components/footer/Footer"
import PricingCard from "../containers/pricingCard/PricingCard"
import { Link } from "gatsby"
import FaqCard from "../components/faqCard/FaqCard"
import "./pricing.css"

const Pricing = () => {
  return (
    <Layout>
      <NavStatic />
      {/* <PricingCard/> */}
      {/* <div className='pricing-header_container' style={{padding:'1rem'}}>
            <h2 className='pricing-header_title' style={{marginBottom:'0'}} >Recover lost revenue & see positive ROI in 30 days</h2>
            </div>
            
            <div className='pricing-header_container'>
                <div className="pricing-plan-card">
                <div>
                    <h3 className='pricing-plan-card_title'>Get Started</h3>
                    <h4 className='pricing-plan-card_price'>Start revenue recovery - today!</h4>
                    <p className='pricing-plan-card_para'> Pricing available for stores of all sizes - no commitment</p>
               
                </div>
                <div className='pricing-plan-card__button-container'>
                    <Link  className='btn btn-primary --max-width' to="/book-demo">Let's Talk</Link>
                </div>

                </div>
            </div> */}
      <FaqCard />
      <Footer />
    </Layout>
  )
}

export default Pricing
