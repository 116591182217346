import React from 'react'
import './FaqCard.css'
const FaqCard = () => {
    return (
        <div className='faqCard-header_container'>
            <h2 className='faqCard-header_title '>FAQ</h2>
            <h3 className='faqCard-header_subTitle'>How does the free trial work?</h3>
            <p className='faqCard-header_para'>Our 14-day free trial gives you access to all Sizify's features. </p>
            <h3 className='faqCard-header_subTitle'>How do you calculate pageviews?</h3>
            <p className='faqCard-header_para'>One page view is counted each time a page of your website is loaded (meaning one visitor can contribute to multiple page views). If you’re familiar with Google Analytics, this is equivalent to their page view metric.</p>
            <h3 className='faqCard-header_subTitle'>What happens if I reach my plan’s limits?</h3>
            <p className='faqCard-header_para'>
            We’ll let you know when you hit 90% of your limit. If you hit the limit, we’ll stop displaying size guide(s). You can upgrade any time to reactivate. 
            </p>
            <h3 className='faqCard-header_subTitle'>
            What if I want to change plans?
            </h3>
            <p className='faqCard-header_para'>
            You can change your plan any time directly from the Sizify's Shopify app.
            </p>
            <h3 className='faqCard-header_subTitle'>What’s your cancellation policy?</h3>
            <p className='faqCard-header_para'>You can cancel any time without justification or hidden costs.</p>
    </div>
    )
}

export default FaqCard
